import cn from 'classnames';
import FooterLang from 'components/FooterLang';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import config from 'config/template';
import useForm from 'hooks/useForm';
import useProjectInfo from 'hooks/useProjectInfo';
import useTabs from 'hooks/useTabs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from 'store/reducers';
import { Caption } from 'typography';
import { getI18nItems } from 'utils/format';
import { getPathInfo, isEmployeesIntro, replaceLang } from 'utils/versionUtils';
import FooterNav from '../FooterNav';
import styles from './footer.module.scss';

const { polVersion, lang } = getPathInfo();

export interface FooterProps {
  noNav?: boolean;
  isEmployees?: boolean;
  listLangs?: boolean;
}

const Footer: React.FC<FooterProps> = ({
  noNav,
  isEmployees = false,
  listLangs = true
}) => {
  const { showTabs } = useTabs();
  const { t } = useTranslation();
  const { languages, validProject } = useSelector(
    (state: RootState) => state.data
  );
  const location = useLocation();

  const handleChangeLang = (event: any) => {
    const newLang = event.target.value;
    const url = window.location.toString();
    const newURL = replaceLang(url, newLang);
    window.location.href = newURL;
  };
  const pageConfig =
    polVersion === 'employees' ? config.employees : config.externals;

  //const { footerLinks } = pageConfig;
  const footerLinks = getI18nItems(t, `${i18nPath.master}.footer.navigation`);

  const navItems = getI18nItems(t, `${i18nPath.master}.navigation`);

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const { clearSubmit } = useForm();
  const { responsibleDrinkingLink, responsibleDrinkingLabel } =
    useProjectInfo();

  return (
    <footer className={styles.root}>
      {!isEmployeesIntro(location?.pathname) && validProject && (
        <>
          {listLangs && (
            <div className={styles.langSwitch}>
              <FooterLang
                options={languages}
                handleChangeLang={handleChangeLang}
                selected={lang}
              />
            </div>
          )}

          <FooterNav>
            <ul className={cn({ uppercase: isEmployees })}>
              {!noNav &&
                navItems &&
                navItems.map((item, index) => {
                  const { alias } = item;
                  // home path should be an empty string instead of /
                  const path = alias.replace('/', '');
                  return (
                    <li key={index} onClick={() => showTabs(false)}>
                      <Link to={`./${path}?id=${query.get('id')}`}>
                        {t(item.label)}
                      </Link>
                    </li>
                  );
                })}

              {/* TO DO, ask Daniel to add the footer links section to Umbraco */}
              {footerLinks &&
                footerLinks.map((item, index) => {
                  const { alias } = item;
                  const path = alias.replace('/', '');
                  return (
                    <li key={index} onClick={() => showTabs(false)}>
                      <Link to={`./${path}?id=${query.get('id')}`}>
                        {t(item.label)}
                      </Link>
                    </li>
                  );
                })}
              {polVersion !== 'employees' && (
                <li>
                  <a
                    href={responsibleDrinkingLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {responsibleDrinkingLabel}
                  </a>
                </li>
              )}
            </ul>
          </FooterNav>
        </>
      )}

      <div className={styles.logo}>
        <Icon name="icon-carlsber-symbol-fill" size={24} />
      </div>

      <div className={styles.caption}>
        {polVersion !== 'employees' && (
          <span className={styles.topCaption}>
            <Caption key="footerCaption">
              {t(`${i18nPath.master}.footer.caption1`)}
            </Caption>
          </span>
        )}
        <Caption key="footerCaption2">
          {t(`${i18nPath.master}.footer.caption2`)}
        </Caption>
      </div>
    </footer>
  );
};

export default Footer;

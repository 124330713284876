import cn from 'classnames';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body1 } from 'typography';
import styles from './expandBtn.module.scss';

export interface ExpandBtnProps {
  expanded: boolean;
  onClick: (event: any) => void;
}

const ExpandBtn: React.FC<ExpandBtnProps> = ({ expanded, onClick }) => {
  const { t } = useTranslation();

  return (
    <div onClick={onClick} className={styles.root}>
      <Body1>
        {expanded
          ? t(`${i18nPath.dictionary}.collapse`)
          : t(`${i18nPath.dictionary}.readMore`)}
        <span
          className={cn(styles.expandIcon, {
            [styles.expanded]: expanded === true
          })}
        >
          <Icon name="icon-arrow-read-more" size={16} />
        </span>
      </Body1>
    </div>
  );
};

export default ExpandBtn;

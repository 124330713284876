import ContactForm from 'components/ContactForm';
import { i18nPath } from 'config/i18nNs';
import useForm from 'hooks/useForm';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Error from 'screens/Error';
import Success from 'screens/Success';
import { RootState } from 'store/reducers';
import { Body1, Heading1 } from 'typography';
import { getPathInfo } from 'utils/versionUtils';
import styles from './contact.module.scss';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const { market } = getPathInfo();

  const submitSuccess = useSelector(
    (state: RootState) => state.ui.submitSuccess
  );
  const submitFail = useSelector((state: RootState) => state.ui.submitFail);
  const formLoading = useSelector((state: RootState) => state.ui.formLoading);

  const {
    formData,
    errors,
    isValid,
    isDirty,
    handleSubmitForm,
    handleInputChange,
    clearSubmit
  } = useForm(market);

  useEffect(() => {
    clearSubmit();
  }, []);

  useEffect(() => {
    const handleUnload = (e: any) => {
      if (isDirty()) {
        e.preventDefault();
        e.returnValue = 'Changes that you made may not be saved.';
        return '';
      }
      return null;
    };

    window.addEventListener('beforeunload', handleUnload);
  });

  return (
    <>
      {!submitSuccess && !submitFail && (
        <div>
          <Heading1>
            {t(`${i18nPath.master}.pages.contactPage.view.content.title`)}
          </Heading1>
          <Body1>
            {t(`${i18nPath.master}.pages.contactPage.view.content.intro`)}
          </Body1>
          <div className={styles.formSection}>
            <ContactForm
              loading={formLoading}
              data={formData}
              errors={errors}
              handleInputChange={handleInputChange}
              isValid={isValid}
              submitForm={handleSubmitForm}
            />
          </div>
        </div>
      )}

      {submitSuccess && <Success />}

      {submitFail && <Error />}
    </>
  );
};

export default Contact;

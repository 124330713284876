import ExpandBtn from 'components/ExpandBtn';
import FormattedText from 'components/FormattedText';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import useProjectInfo from 'hooks/useProjectInfo';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HallOfFameType } from 'types/policyPages';
import { Body1, Heading4 } from 'typography';
import { getShortContent, textIsLong } from 'utils/format';
import styles from './textBlock.module.scss';

export interface TextBlockProps {
  sectionI18nKey?: string;
  index: number;
  expanded: boolean;
  setActiveTab: (tab: string) => void;
  toggleText: (index: number, expanded: boolean) => void;
  activities?: Array<{ [key: string]: string }>;
  cookieTypes?: Array<{ [key: string]: string }>;
}

const TextBlock: React.FC<TextBlockProps> = ({
  index,
  sectionI18nKey,
  expanded,
  setActiveTab,
  toggleText,
  activities,
  cookieTypes
}) => {
  const { t } = useTranslation();
  const projectInfo = useProjectInfo();

  const section: {
    anchor?: string;
    title: string;
    content: string;
    listActivities?: boolean;
    listCookies?: boolean;
    hallOfFame?: HallOfFameType;
  } = t(`${sectionI18nKey}`);
  const content = t(`${sectionI18nKey}.content`, projectInfo);

  const text =
    typeof content === 'string' && textIsLong(content) && !expanded
      ? getShortContent(content)
      : content;

  return (
    <article key={`article${index}`} className={styles.article}>
      <Heading4>
        <a id={section.anchor} />
        {section.title}
      </Heading4>

      <FormattedText i18nKey={text} />

      {textIsLong(section.content) && (
        <ExpandBtn
          expanded={expanded}
          onClick={() => toggleText(index, !expanded)}
        />
      )}

      {section.listActivities && (
        <ul className={styles.activitiesList}>
          {activities &&
            Array.isArray(activities) &&
            activities.map((item) => (
              <li key={item.tabId} onClick={() => setActiveTab(item.tabId)}>
                <Body1 hasIcon>
                  <Icon name="icon-diagonal-up-right" size={18} />
                  {item.label}
                </Body1>
              </li>
            ))}
        </ul>
      )}

      {section.listCookies && (
        <div className={styles.details}>
          {cookieTypes &&
            Array.isArray(cookieTypes) &&
            cookieTypes.map((item) => (
              <div key={item.title} className={styles.topic}>
                <Body1 bold>{item.title}</Body1>
                <FormattedText i18nKey={item.intro} />
                <div
                  className={styles.moreInfo}
                  onClick={() => setActiveTab(item.tabId)}
                >
                  <Body1>
                    {t(`${i18nPath.dictionary}.knowMoreCookies`)}
                    <Icon name="icon-arrow-know-more" size={16} />
                  </Body1>
                </div>
              </div>
            ))}
        </div>
      )}

      {section.hallOfFame &&
        Array.isArray(section.hallOfFame.view.items) &&
        section.hallOfFame?.view?.items?.map((item) => (
          <div key={item.name} className={styles.hofContainer}>
            <Body1 className={styles.hallOfFameName}>{item?.name}</Body1>
            <Body1 hasIcon>
              <Icon name="icon-survey-trophy" size={18} />
              {item?.description}
            </Body1>
            <Body1 hasIcon>
              <Icon name="icon-calendar1" size={18} />
              {moment(item?.date).format('DD/MM/YY')}
            </Body1>
          </div>
        ))}


    </article>
  );
};

export default TextBlock;

import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { getPathInfo, replaceLang } from 'utils/versionUtils';
import styles from './langSwitcher.module.scss';

export interface LangSwitcherProps {
  options: Array<string>;
}

const LangSwitcher: React.FC<LangSwitcherProps> = ({ options }) => {
  const { lang } = getPathInfo();
  const selectRef = useRef(null);

  const [selected, setSelected] = useState(lang);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const handleClickOutside = (e: any) => {
    if (
      e.target !== selectRef.current &&
      e.target.getAttribute('data-ref') !== 'option'
    ) {
      setOptionsOpen(false);
    }
  };

  const handleClick = (e: any) => {
    const value = e.target.getAttribute('data-name');
    setSelected(value);
    setOptionsOpen(false);
    const url = window.location.toString();
    const newURL = replaceLang(url, value);
    window.location.href = newURL;
  };

  const toggleList = () => setOptionsOpen(!optionsOpen);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={cn(styles.root, { [styles.active]: optionsOpen })}
      onClick={toggleList}
      ref={selectRef}
    >
      {selected}
      {optionsOpen && (
        <ul className={styles.list}>
          {options.map((option: any, index: number) => {
            const langCode = option;
            const isSelected = langCode === selected;
            return (
              <li
                className={cn({ [styles.selected]: isSelected })}
                data-name={langCode}
                data-ref="option"
                key={`option${index}`}
                onClick={handleClick}
              >
                {langCode}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default LangSwitcher;

import React from 'react';
import { Link } from 'react-router-dom';

export const getPageLinks = (
  id: string,
  content: string,
  closeTabs: () => void
) => {
  const links = {
    link_contact: <Link to={`./contact?id=${id}`} onClick={closeTabs} />,
    link_cookies: <Link to={`./cookies?id=${id}`} onClick={closeTabs} />,
    link_privacy: <Link to={`./privacy?id=${id}`} onClick={closeTabs} />,
    link_terms: <Link to={`./terms?id=${id}`} onClick={closeTabs} />,
    link_use: <Link to={`./acceptable-use?id=${id}`} onClick={closeTabs} />
  };

  const hashLinkRegExp = /<.*?#.*?>/g;

  const hasHashLinks = hashLinkRegExp.test(content);

  const extractEvenOccurrences = (matches: string[]) => {
    const evenOccurrences = [];
    for (let i = 0; i < matches.length; i++) {
      const isEven = i % 2 === 0;
      if (isEven) {
        // Check if the index is even
        evenOccurrences.push(matches[i]);
      }
    }
    return evenOccurrences;
  };

  const extractHashLinks = (content: string) => {
    const regexMatches = /<([^<>]*#[^<>]*)>/g;
    // Extract the pattern from the string
    const matches = content.match(regexMatches);
    const hashLinks = matches ? matches : null;
    const openingHashLinks = hashLinks
      ? extractEvenOccurrences(hashLinks)
      : null;
    return openingHashLinks;
  };

  const getHashLinks = (i18nContent: string) => {
    const hashLinks = extractHashLinks(i18nContent);
    const newLinks = { ...links };
    for (let i = 0; i < hashLinks.length; i++) {
      const hashLink: string = hashLinks[i];
      const linkId = hashLink.replace(/</g, '').replace(/>/g, '');
      const link = hashLink
        .substring(0, hashLink.indexOf('#'))
        .replace(/<link_/g, '');
      const hash = hashLink
        .substring(hashLink.indexOf('#') + 1)
        .replace(/>/g, '');
      newLinks[linkId] = (
        <Link to={`./${link}?id=${id}#${hash}`} onClick={closeTabs} />
      );
    }
    return newLinks;
  };

  const linksWithHash = hasHashLinks ? getHashLinks(content) : links;

  return hasHashLinks ? getHashLinks(content) : links;
};

import logo from 'assets/img/cbg-logo.svg';
import cn from 'classnames';
import LangSwitcher from 'components/LangSwitcher';
import useMenu from 'hooks/useMenu';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from 'store/reducers';
import { isEmployeesIntro, isSoMeTab } from 'utils/versionUtils';
import Menu from '../Menu';
import MenuBtn from '../MenuBtn';
import NavBar from '../NavBar';
import styles from './header.module.scss';

export interface HeaderProps {
  isEmployees: boolean;
  listLangs?: boolean;
  noNav?: boolean;
  alias?: string;
}

const Header: React.FC<HeaderProps> = ({
  noNav = false,
  isEmployees,
  listLangs = true,
  alias = ''
}) => {
  const { isActive, toggleActive } = useMenu();
  const { languages, validProject } = useSelector(
    (state: RootState) => state.data
  );
  const location = useLocation();
  const isSpeakUp = alias === 'speak-up';

  return (
    <div className={cn(styles.root, isSpeakUp && styles.isSpeakUp)}>
      <div className={cn(!noNav && styles.centeredLogo)}>
        <img src={logo} alt="carlsberg group logo" />
      </div>
      {!isEmployeesIntro(location?.pathname) && validProject && (
        <>
          {!noNav && <MenuBtn active={isActive} toggleActive={toggleActive} />}
          <Menu active={isActive}>
            <div
              className={cn(
                styles.nav,
                languages?.length > 1 && styles.fullWidth
              )}
            >
              {!noNav && (
                <NavBar toggleActive={toggleActive} isEmployees={isEmployees} />
              )}
              {listLangs && (
                <div className={styles.lang}>
                  {languages?.length > 1 && !isSoMeTab(location?.pathname) && (
                    <LangSwitcher options={languages} />
                  )}
                </div>
              )}
            </div>
          </Menu>
        </>
      )}
    </div>
  );
};

export default Header;

export const domain = window.location.origin;

export async function makeRequest(url: string, auth?: string) {
  const controller = new AbortController();
  const { signal } = controller;

  setTimeout(() => controller.abort(), 8000);

  const requestConfig = auth
    ? {
        headers: { 'X-API-KEY': auth },
        signal
      }
    : { signal };

  const successResponse = await fetch(url, requestConfig)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      return response.json();
    })
    .catch((error) => {
      throw error;
    });

  return successResponse;
}

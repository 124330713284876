import cn from 'classnames';
import React from 'react';
import styles from './button.module.scss';

export interface ButtonProps {
  children: any;
  onClick?: (event: any) => void;
  variant?: string;
  size?: string;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  variant = 'primary',
  size = 'medium',
  disabled = false,
  loading = false,
  fullWidth = false
}: ButtonProps) => (
  <button
    type="button"
    className={cn(styles.button, {
      [styles.primary]: variant === 'primary',
      [styles.secondary]: variant === 'secondary',
      [styles.large]: size === 'large',
      [styles.disabled]: disabled === true,
      [styles.loading]: loading === true,
      [styles.fullWidth]: fullWidth === true
    })}
    onClick={onClick}
  >
    <span className={styles.label}> {children} </span>

    <div className={styles.load}>
      {loading && <div className={styles.loadIcon} />}
    </div>
  </button>
);

export default Button;

import { i18nPath } from 'config/i18nNs';
import useTabs from 'hooks/useTabs';
import useToggleText from 'hooks/useToggleText';
import TextBlock from 'layout/TextBlock';
import React from 'react';

export interface PolicyMainProps {
  sections: Array<{
    anchor: string;
    title: string;
    content: string;
    listActivities?: boolean;
  }>;
  activities: Array<{ [key: string]: string }>;
  cookieTypes: Array<{ [key: string]: string }>;
  pageIndex: number;
}

const PolicyMain: React.FC<PolicyMainProps> = ({
  sections = [],
  activities,
  cookieTypes,
  pageIndex
}) => {
  const { toggleText, isExpanded } = useToggleText();
  const { setActiveTab } = useTabs();

  return (
    <div>
      {sections &&
        sections.map((section, index) => {
          const expanded = isExpanded(index) || false;
          const handleToggleText = () => toggleText(index, !expanded);

          return (
            <TextBlock
              key={section.title}
              sectionI18nKey={`${i18nPath.policyPages}.${pageIndex}.sections.${index}`}
              index={index}
              expanded={expanded}
              setActiveTab={setActiveTab}
              toggleText={handleToggleText}
              activities={activities}
              cookieTypes={cookieTypes}
            />
          );
        })}
    </div>
  );
};

export default PolicyMain;

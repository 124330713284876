import Breadcrumbs from 'components/Breadcrumbs';
import CookieCard from 'components/CookieCard';
import FormattedText from 'components/FormattedText';
import Select from 'components/Select';
import { i18nPath } from 'config/i18nNs';
import useTabs from 'hooks/useTabs';
import useToggleText from 'hooks/useToggleText';
import TextBlock from 'layout/TextBlock';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body1, Heading2 } from 'typography';
import styles from './policyTab.module.scss';

export interface PolicyTabProps {
  id: string;
  i18nPrefix: string;
  locali18nPrefix?: string;
  selectOptions: Array<{ label: string; value: string }>;
  content: {
    title: string;
    label: string;
    tabId: string;
    sections?: Array<{
      title: string;
      content: string;
      anchor: string;
    }>;
    intro?: string;
    details?: string;
    listTitle?: string;
    cardLabels?: { [key: string]: string };
  };
  thirdPartyCookies?: Array<{
    title?: string;
    desc?: string;
    link?: string;
  }>;
  categoryCookies?: Array<{
    name: string;
    source: string;
    purpose?: string;
    duration?: string;
    additionalInfo: string;
  }>;
  localContent?: {
    sections?: Array<{
      title: string;
      content: string;
    }>;
  };
}

const PolicyTab: React.FC<PolicyTabProps> = ({
  id,
  i18nPrefix,
  locali18nPrefix,
  content,
  localContent,
  selectOptions,
  thirdPartyCookies,
  categoryCookies
}) => {
  const { t } = useTranslation();
  const { tabId } = content;
  const { setActiveTab, activeTab } = useTabs();
  const { toggleText, isExpanded } = useToggleText();

  const pages: Array<{
    page: string;
    alias: string;
    sections: Array<{
      label: string;
      anchor: string;
      icon: string;
      title: string;
      content: string;
    }>;
    hideNavigation?: boolean;
  }> = t(`${i18nPath.policyPages}`);

  const currentPage: any =
    (Array.isArray(pages) && pages.find((page) => page?.alias === id)) || null;
  return (
    <div className={styles.root}>
      <>
        <Breadcrumbs
          pageTitle={currentPage?.content.title}
          activeTab={`${currentPage?.breadcrumbs.prefix}-${content.label}`}
        />

        <div className={styles.mobile}>
          <Select
            handleChange={setActiveTab}
            options={selectOptions}
            value={content && content.title}
          />
        </div>

        <div className={styles.desktop}>
          <Heading2> {content && content.title} </Heading2>
        </div>

        {content.intro && <FormattedText i18nKey={content.intro} />}

        {content?.sections &&
          content.sections.map((section: any, index: number) => {
            const expanded = isExpanded(`${activeTab}-${index}`) || false;
            const handleToggleText = () =>
              toggleText(`${activeTab}-${index}`, !expanded);

            return (
              <TextBlock
                key={section.title}
                sectionI18nKey={`${i18nPrefix}.sections.${index}`}
                index={index}
                expanded={expanded}
                setActiveTab={setActiveTab}
                toggleText={handleToggleText}
              />
            );
          })}

        {localContent?.sections &&
          localContent.sections.map((section: any, index: number) => {
            const expanded = isExpanded(`${activeTab}-local-${index}`) || false;
            const handleToggleText = () =>
              toggleText(`${activeTab}-local-${index}`, !expanded);

            return (
              <TextBlock
                key={section.title}
                sectionI18nKey={`${locali18nPrefix}.sections.${index}`}
                index={index}
                expanded={expanded}
                setActiveTab={setActiveTab}
                toggleText={handleToggleText}
              />
            );
          })}

        {content.details && <FormattedText i18nKey={content.details} />}

        {thirdPartyCookies && tabId === 'marketing' && (
          <div className={styles.thirdParty}>
            {thirdPartyCookies.map((cookie, index) => {
              const { title } = cookie;
              const { desc } = cookie;
              const { link } = cookie;

              return (
                <div key={`thirdParty-${index}`}>
                  {title && <Body1 bold> {title} </Body1>}
                  {desc && <Body1> {desc} </Body1>}
                  {link && (
                    <Body1>
                      <a href={link}> {link} </a>
                    </Body1>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {content.listTitle && categoryCookies.length > 0 && (
          <FormattedText i18nKey={content.listTitle} />
        )}

        {categoryCookies &&
          categoryCookies.length > 0 &&
          categoryCookies.map((card, index) => (
            <CookieCard key={`card${index}`} info={card} />
          ))}
      </>
    </div>
  );
};

export default PolicyTab;

const idsConfig = {
  dev: {
    global: '70f31651-08b3-4d3f-8b38-95b8bb15e3d5',
    excom: '909f83e2-8d3f-4eae-9637-08bceb42e2f7'
  },
  tst: {
    global: '909f83e2-8d3f-4eae-9637-08bceb42e2f7',
    excom: '909f83e2-8d3f-4eae-9637-08bceb42e2f7'
  },
  stg: {
    global: 'f431e016-4434-433c-9b68-62c0ba42f325',
    excom: 'a9414b68-5fca-4e5d-8ef1-9869653d8d76'
  },
  prd: {
    global: '5823b042-e26b-45e5-9fc8-d52d196fb63f',
    excom: '29891e34-86fc-4ec9-8bb8-487e985ab232'
  }
};

const HQemployeesId = {
  dev: '1df2b8a2-56e8-4a33-88b1-a6d65745cd5d',
  tst: 'b4c8a5c0-99e0-4af5-8ea9-54502be1b8f3',
  stg: 'b4c8a5c0-99e0-4af5-8ea9-54502be1b8f3',
  prd: '8a65dec9-fa29-46da-ac26-b6691c058224'
};

export { idsConfig, HQemployeesId };

import cn from 'classnames';
import Icon from 'components/Icon';
import SubNav from 'components/SubNav';
import useTabs from 'hooks/useTabs';
import React from 'react';
import styles from './sideNav.module.scss';

export interface SideNavProps {
  navItems: Array<{
    label: string;
    icon?: string;
    anchor: string;
    listActivities?: boolean;
    listCookies?: boolean;
  }>;
  activities: Array<{ [key: string]: string }>;
  cookieTypes: Array<{ [key: string]: string }>;
  isEmployees: boolean;
}

const SideNav: React.FC<SideNavProps> = ({
  navItems,
  activities,
  cookieTypes,
  isEmployees
}) => {
  const { showTabs } = useTabs();

  return (
    <div className={styles.root}>
      <ul className={styles.menu}>
        {navItems &&
          navItems.map((item, index) => (
            <li key={`nav-${index}`}>
              {item.icon && <Icon name={item.icon} />}
              <a
                className={cn({ uppercase: isEmployees })}
                href={`#${item.anchor}`}
                onClick={() => showTabs(false)}
              >
                {item.label}
              </a>
              {(item.listActivities || item.listCookies) && (
                <SubNav
                  isEmployees={isEmployees}
                  items={item.listActivities ? activities : cookieTypes}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SideNav;

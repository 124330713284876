import { i18nPath } from 'config/i18nNs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

const useProjectInfo = () => {
  const projectData = useSelector((state: RootState) => state.data.projectData);
  const { t } = useTranslation(['local']);

  const { siteContactEmail, isCorporate, soMeContactEmail, legalEntities } =
    projectData;

  return {
    isCorporate,
    soMeContactEmail,
    siteContactEmail,
    legalEntities,
    dataProtectionAgency: t(`${i18nPath.local}.content.dataProtection.agency`),
    dataProtectionLink: t(`${i18nPath.local}.content.dataProtection.link.url`),
    dataLinkLabel: t(`${i18nPath.local}.content.dataProtection.link.name`),
    responsibleDrinkingLink: t(
      `${i18nPath.local}.content.responsibleDrinking.link.url`
    ),
    responsibleDrinkingLabel: t(
      `${i18nPath.local}.content.responsibleDrinking.link.name`
    ),
    termsOfSaleLink: t(`${i18nPath.local}.content.termsOfSale.link.url`)
  };
};

export default useProjectInfo;

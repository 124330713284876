import FormattedText from 'components/FormattedText';
import { i18nPath } from 'config/i18nNs';
import { marketLocalLang } from 'config/locale';
import countries from 'i18n-iso-countries';
import EmployerSelect from 'layout/EmployerSelect';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchEmployers } from 'store/actions';
import { RootState } from 'store/reducers';
import { AuxTitle, Body1, Heading1 } from 'typography';
import styles from './employers.module.scss';

const EmployersScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { employers } = useSelector((state: RootState) => state.data);
  const [employersDisabled, setEmplDisabled] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [employersList, setEmployers] = useState([]);
  const [selectedCountry, setCountry] = useState({
    value: null,
    label: 'Country'
  });
  const [selectedEmpl, setEmpl] = useState({
    value: null,
    label: 'Employer'
  });

  const handleSubmit = () => {
    const market: string = selectedCountry?.value?.toLowerCase();
    const projectId = selectedEmpl?.value;
    const localLang = marketLocalLang[market] || 'en';

    const policiesURL = `/employees/${localLang}-${market}/?id=${projectId}`;

    window.location.href = policiesURL;
  };

  const handleChangeCountry = (country: { value: string; label: string }) => {
    const countryCode = country?.value;
    const countryName = country?.label;
    const employersArray = employers?.[countryCode];

    setCountry({ value: countryCode, label: countryName });

    const employersToSet = employersArray.map((employer) => ({
      value: employer.uuid,
      label: employer.formalEntityName
    }));

    setEmpl({
      value: null,
      label: 'Employer'
    });
    setEmployers(employersToSet);

    if (countryCode) {
      setEmplDisabled(false);
    } else {
      setEmplDisabled(true);
    }
  };

  const handleChangeEmpl = (employer: { value: string; label: string }) => {
    const projectId = employer?.value;
    const entityName = employer?.label;

    setEmpl({ value: projectId, label: entityName });

    if (projectId) {
      setSubmitDisabled(false);
    }
  };

  useEffect(() => {
    dispatch(fetchEmployers());
  }, []);

  const getCountriesList = (employersObj: {
    [key: string]: Array<{ uuid: string; formalEntityName: string }>;
  }) => {
    const countriesArray = Object.keys(employersObj);

    const countryOptions = countriesArray.map((countryCode: string) => {
      const countryName = countries.getName(countryCode, 'en');

      return {
        value: countryCode,
        label: countryName
      };
    });

    return countryOptions;
  };

  const countriesList = getCountriesList(employers);

  return (
    <>
      <Row className={styles.intro}>
        <Col sm={12} className="column">
          <Link to="/employees/home">
            <Body1>
              {'<'} {t(`${i18nPath.dictionary}.back`)}
            </Body1>
          </Link>
          <AuxTitle>{t(`${i18nPath.dictionary}.corporateTitle`)}</AuxTitle>

          <Heading1>
            <span className="uppercase">
              {t(`${i18nPath.dictionary}.selectEmployerTitle`)}
            </span>
          </Heading1>

          <FormattedText
            i18nKey={`${i18nPath.dictionary}.selectEmployerIntro`}
          />
        </Col>
      </Row>

      <EmployerSelect
        countriesList={countriesList}
        employersList={employersList}
        selectedCountry={selectedCountry}
        selectedEmployer={selectedEmpl}
        handleChangeCountry={handleChangeCountry}
        handleChangeEmpl={handleChangeEmpl}
        employersDisabled={employersDisabled}
        submitDisabled={submitDisabled}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default EmployersScreen;

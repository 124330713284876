const i18nPath = {
  dictionary: 'dictionary:views.dictionary.translations',
  global: 'views.global',
  local: 'local:views.marketVariant',
  globalVariant: 'local:views.marketVariant.global.view',
  homepage: 'homepage:views.employeesHomepage',
  cookies: 'cookies-list:views.cookiesList',
  master: 'master:views.masterPage',
  policyPages: 'local:views.marketVariant.policyPages',
  speakUp: 'speakUp:views.speakUp'
};

export { i18nPath };

import useTabs from 'hooks/useTabs';
import React from 'react';
import styles from './breadcrumbs.module.scss';

export interface BreadcrumbsProps {
  pageTitle: string;
  activeTab: string;
}

const Breadcrumbs = ({ pageTitle = 'policy', activeTab = 'policy detail' }) => {
  const { showTabs } = useTabs();

  return (
    <div className={styles.root}>
      <p>
        <span onClick={() => showTabs(false)}> {pageTitle} </span>
        {'>'}
        <span className={styles.active}> {activeTab} </span>
      </p>
    </div>
  );
};

export default Breadcrumbs;

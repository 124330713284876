import { DATACAT_API_URL } from 'config/api';

const sendDatacatError = async (payload: {
  type: string;
  url: string;
  error: string;
  referrer?: string;
}) => {
  try {
    const { referrer } = document;
    const data = referrer ? { ...payload, referrer } : payload;
    const res = await fetch(DATACAT_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!res.ok) {
      console.error('error sending datacat error');
      return null;
    }

    const response = res.json();
    return response;
  } catch (err) {
    console.error('error sending datacat error');
    console.error(err);
    return null;
  }
};

export { sendDatacatError };

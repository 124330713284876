const getSelectOptions = (data: any) => {
  const selectOptions: Array<{ label: string; value: string }> =
    data &&
    data.map((item: any) => ({
      label: item.label,
      value: item.tabId
    }));

  return selectOptions;
};

const maxLength = 550;

const textIsLong = (content: string) => {
  const exceedsMaxLength = content && content.length > maxLength;
  const hasBreakingTags =
    content &&
    (content.includes('<break></break') || content.includes('</li>'));
  const isLong = content && hasBreakingTags && exceedsMaxLength;

  if (!isLong) {
    return false;
  }
  return isLong;
};

const getShortContent = (content: string) => {
  const contentCopy = content.slice();

  let splitContent;
  let shortContent;

  if (content.includes('<break></break')) {
    splitContent = contentCopy.split('<break></break>');
    const part1 = splitContent[0].toString();
    const part2 = splitContent[1] && splitContent[1].toString();
    const part3 = splitContent[2] && splitContent[2].toString();

    if (!part2.trim().startsWith('-')) {
      return part1;
    }

    const block1 = splitContent[0]
      .concat(`<break></break>${splitContent[1]}`)
      .toString();

    return part2.length < 100 && part3
      ? block1.concat(`<break></break>${splitContent[2]}`).toString()
      : block1;
  }

  if (content.includes('</li>')) {
    splitContent = contentCopy.split('</li>').slice(0, 2);
    shortContent = splitContent.join('</li>').toString();
    return shortContent;
  }

  return content;
};

const getI18nItems = (
  t: (key: string) => string,
  i18nKey: string
): {
  alias: string;
  id?: string;
  type?: string;
  label?: string;
  hideNavigation?: boolean;
  displaySpeakUp?: boolean;
  sections?: {
    label: string;
    anchor: string;
    icon: string;
    title: string;
    content: string;
  }[];
}[] => {
  const transValue = t(i18nKey);
  if (Array.isArray(transValue)) {
    return transValue;
  }
  return [];
};

export { getI18nItems, getSelectOptions, getShortContent, textIsLong };

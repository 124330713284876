import { ProjectType } from 'types/project';
import * as constants from '../constants';

export interface DataState {
  languages: Array<string>;
  employers: {
    [countryCode: string]: Array<{
      uuid: string;
      formalEntityName: string;
    }>;
  };
  projectData: ProjectType;
  localData: any;
  fromUrl: string;
  projectId: string;
  validProject: boolean;
  formData: {
    name: string;
    email: string;
    message: string;
  };
  loading: boolean;
  formErrors: { [key: string]: boolean };
}

const initialState: DataState = {
  projectData: {
    cookies: [],
    isCorporate: false,
    legalEntities: []
  },
  employers: {},
  validProject: false,
  localData: {},
  fromUrl: null,
  projectId: null,
  formData: { name: '', email: '', message: '' },
  loading: true,
  formErrors: {},
  languages: []
};

const dataReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case constants.FETCH_PROJECT_SUCCESS:
      return { ...state, projectData: action.payload.data, validProject: true };
    case constants.SET_FORM_DATA:
      return { ...state, formData: action.payload.data };
    case constants.SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.payload.errors
      };
    case constants.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload.languages
      };
    case constants.SET_EMPLOYERS:
      return {
        ...state,
        employers: action.payload.employers
      };
    default:
      return state;
  }
};

export { dataReducer };

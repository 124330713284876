import countries from 'i18n-iso-countries';
import enLang from 'i18n-iso-countries/langs/en.json';
import { LegalEntityType, ParsedEntityType } from 'types/project';
import { getPathInfo } from 'utils/versionUtils';

countries.registerLocale(enLang);
const { lang } = getPathInfo();

const getDefaultEntity = (
  entitiesList: LegalEntityType[]
): ParsedEntityType => {
  if (!Array.isArray(entitiesList)) {
    console.warn('entities list is not an array');
    return {
      entityName: '',
      formalEntityName: '',
      entityRegNumber: '',
      entityAddress: '',
      entityCountry: ''
    };
  }

  // TO DO, convert entityCountry from code to full name
  const { address, country, formalName, name, regNumber } =
    entitiesList[0] || {};

  const entityCountry = countries.getName(country, lang);

  return {
    entityName: name,
    formalEntityName: formalName,
    entityRegNumber: regNumber,
    entityAddress: address,
    entityCountry
  };
};

const parseEntitiesContent = (
  entity: LegalEntityType,
  content: string,
  defaultEntity: ParsedEntityType
) => {
  const {
    formalEntityName,
    entityName,
    entityAddress,
    entityCountry,
    entityRegNumber
  } = defaultEntity;

  const { address, country, formalName, name, regNumber } = entity;

  const parsedCountry = countries.getName(country, lang);

  const textContent = content.toString();
  const newText = textContent
   
    .replace(entityCountry, "<<parsedCountry>>")
    .replace(formalEntityName, "<<formalName>>")
    .replace(entityName, "<<name>>")
    .replace(entityAddress, "<<address>>")
    .replace(entityRegNumber, "<<regNumber>>")
    .replace("<<parsedCountry>>", parsedCountry)
    .replace("<<formalName>>", formalName)
    .replace("<<name>>", name)
    .replace("<<address>>", address)
    .replace("<<regNumber>>", regNumber)
  return newText;
};

export { getDefaultEntity, parseEntitiesContent };

import { i18nPath } from 'config/i18nNs';
import useTabs from 'hooks/useTabs';
import PolicyTab from 'layout/PolicyTab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getSelectOptions } from 'utils/format';

export interface CookieProps {
  id: string;
}

const CookieDetails: React.FC<CookieProps> = ({ id }) => {
  const { t } = useTranslation();
  const { activeTab } = useTabs();
  const projectData = useSelector((state: RootState) => state.data.projectData);
  const cookieTypes: Array<{
    title: string;
    tabId: string;
    content: string;
    label: string;
  }> = t(`${i18nPath.globalVariant}.cookies.types`);

  const { cookies } = projectData;

  const cookiesList: Array<{
    name: string;
    source: string;
    purpose?: string;
    duration?: string;
    additionalInfo: string;
  }> = t(`${i18nPath.cookies}.${activeTab}`);

  const thirdPartyCookies: Array<{
    title?: string;
    desc?: string;
    link?: string;
  }> = t(`${i18nPath.globalVariant}.cookies.thirdPartyCookies`);

  const tabContent =
    cookieTypes && cookieTypes.find((cookie) => cookie.tabId === activeTab);
  const selectOptions = getSelectOptions(cookieTypes);
  return (
    <PolicyTab
      categoryCookies={cookiesList}
      thirdPartyCookies={thirdPartyCookies}
      id={id}
      i18nPrefix="cookieTypes"
      content={tabContent}
      selectOptions={selectOptions}
    />
  );
};

export default CookieDetails;

import { useState } from 'react';

const useMenu = () => {
  const [isActive, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!isActive);
  };

  return { isActive, toggleActive };
};

export default useMenu;

import cn from 'classnames';
import Button from 'components/Button';
import FormattedText from 'components/FormattedText';
import Icon from 'components/Icon';
import SideNav from 'components/SideNav';
import { i18nPath } from 'config/i18nNs';
import CookieDetails from 'containers/CookieDetails';
import PrivacyDetails from 'containers/PrivacyDetails';
import useTabs from 'hooks/useTabs';
import PolicyMain from 'layout/PolicyMain';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SpeakUp from 'screens/SpeakUp';
import { AuxTitle, Heading1 } from 'typography';
import { getI18nItems } from 'utils/format';
import {
  initSmoothScroll,
  scrollToAnchor,
  scrollToTop
} from 'utils/scrollPage';
import { getPathInfo } from 'utils/versionUtils';
import styles from './policy.module.scss';

const { polVersion } = getPathInfo();

export interface PolicyProps {
  id: string;
  displaySpeakUp?: boolean;
}

const Policy: React.FC<PolicyProps> = ({ id, displaySpeakUp = false }) => {
  const { t } = useTranslation(['local', 'activities']);
  const { isTabsVisible } = useTabs();
  const location = useLocation();
  const { hash } = location;
  const { lang } = getPathInfo();
  const isRTL = lang === 'ug';

  useEffect(() => {
    initSmoothScroll();
  }, []);

  useEffect(() => {
    if (hash) {
      scrollToAnchor(hash);
    }
  }, [location]);

  const pages = getI18nItems(t, `${i18nPath.policyPages}`);

  const currentPage: any =
    (Array.isArray(pages) && pages.find((page) => page.alias === id)) || null;

  const pageIndex = pages.indexOf(currentPage);

  let sections: Array<{
    label: string;
    anchor: string;
    icon: string;
    title: string;
    content: string;
  }> = currentPage && currentPage.sections;

  if (!Array.isArray(sections)) {
    sections = [];
  }

  const shouldDisplayBackBtn =
    polVersion !== 'employees' && sections.length > 1;

  const activities: Array<{ [key: string]: string }> = t(
    `${i18nPath.globalVariant}.activities`
  );
  const cookieTypes: Array<{ [key: string]: string }> = t(
    `${i18nPath.globalVariant}.cookies.types`
  );

  let tabComponent;

  if (id === 'cookies') {
    tabComponent = <CookieDetails id={id} />;
  }
  if (id === 'privacy') {
    tabComponent = <PrivacyDetails id={id} />;
  }

  return (
    <>
      <section
        className={cn({
          [styles.hideIntro]: isTabsVisible === true
        })}
      >
        <Row>
          <Col sm={12}>
            {polVersion === 'employees' && (
              <AuxTitle>
                {t(`${i18nPath.globalVariant}.content.policyTitle`)}
              </AuxTitle>
            )}
            <Heading1>
              <span
                className={cn(
                  { uppercase: polVersion === 'employees' },
                  { [styles.rtl]: displaySpeakUp && isRTL }
                )}
              >
                {t(currentPage && currentPage.content.title)}
              </span>
            </Heading1>
            <div className={cn({ [styles.rtl]: displaySpeakUp && isRTL })}>
              <FormattedText
                i18nKey={`${i18nPath.policyPages}.${pageIndex}.content.intro`}
              />
            </div>
          </Col>
        </Row>
      </section>

      {sections && sections.length > 0 && (
        <section className={styles.details}>
          <div className={styles.sideNav}>
            <SideNav
              isEmployees={polVersion === 'employees'}
              navItems={sections}
              activities={activities}
              cookieTypes={cookieTypes}
            />
          </div>
          <div className={styles.content}>
            {isTabsVisible ? (
              tabComponent
            ) : (
              <div>
                <PolicyMain
                  pageIndex={pageIndex}
                  sections={sections}
                  activities={activities}
                  cookieTypes={cookieTypes}
                />
              </div>
            )}
          </div>
        </section>
      )}

      {displaySpeakUp && <SpeakUp />}

      {shouldDisplayBackBtn && (
        <div className={styles.bottomBtn}>
          <Button size="large" onClick={scrollToTop}>
            <Icon name="icon-arrow-up" size={16} />
            {t(`${i18nPath.dictionary}.backToTop`)}
          </Button>
        </div>
      )}
    </>
  );
};

export default Policy;

import {
  API_BASE_URL,
  EMPLOYERS_URL,
  UMBRACO_API_BASE_URL,
  UMBRACO_API_KEY
} from 'config/api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getPoliciesType, getUmbracoPolVersion } from 'utils/versionUtils';
import { setEmployers, setLanguages } from '../actions';
import * as constants from '../constants';
import { makeRequest } from '../utils';

function* getEmployersSaga() {
  const url = `${API_BASE_URL}/${EMPLOYERS_URL}`;
  try {
    const data = yield call(makeRequest, url);
    yield put(setEmployers(data));
  } catch (error) {
    console.error(error);
    console.error('error fetching employers');
  }
}

function* getLanguagesSaga(action: {
  type: 'string';
  payload: {
    polVersion: string;
    market: string;
    isCorporate: boolean;
    projectId?: string;
    isSpeakUp?: boolean;
  };
}) {
  const { polVersion, market, isCorporate, projectId, isSpeakUp } = action.payload;

  const policyVerUmbraco = getUmbracoPolVersion(polVersion);
  const url = `${UMBRACO_API_BASE_URL}/?handle=cultures$pg=${policyVerUmbraco}$mkt=${market}`;

  try {
    const data = yield call(makeRequest, url, UMBRACO_API_KEY);
    const type = isSpeakUp ? 'speakUp' : getPoliciesType(polVersion, isCorporate, projectId);

    const languagesArray =
      data?.views?.cultures?.availableLanguages?.[type] || [];

    if (Array.isArray(languagesArray) && languagesArray.length < 1) {
      console.warn('no languages configuration found for the current market');
    }

    if (typeof languagesArray === 'undefined') {
      yield put(setLanguages([]));
      return;
    }

    // Sort langs alphabetically
    languagesArray.sort((a: any, b: any) => a.localeCompare(b));

    yield put(setLanguages(languagesArray));
  } catch (error) {
    console.error(error);
    console.error('error fetching languages list for current market');
  }
}

function* dataSaga() {
  yield takeLatest(constants.FETCH_LANGUAGES, getLanguagesSaga);
  yield takeLatest(constants.FETCH_EMPLOYERS, getEmployersSaga);
}

export { dataSaga };

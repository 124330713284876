import Button from 'components/Button';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Heading1 } from 'typography';
import { getPathInfo } from 'utils/versionUtils';
import styles from './success.module.scss';

const Success: React.FC = () => {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const { market, lang } = getPathInfo();

  const backToHome = () => {
    window.location.href = `/${lang}-${market}?id=${query.get('id')}`;
  };

  return (
    <div>
      <div className={styles.success}>
        <div className={styles.title}>
          <Heading1>
            <Trans
              i18nKey={`${i18nPath.master}.pages.contactPage.view.messages.success.title`}
            />
          </Heading1>
        </div>

        <Trans
          i18nKey={`${i18nPath.master}.pages.contactPage.view.messages.success.description`}
          components={{
            break: <span className="break" />
          }}
        />

        <div className={styles.button}>
          <Button size="large" onClick={backToHome}>
            <Icon name="icon-diagonal-up-left" size={16} />
            {t(`${i18nPath.dictionary}.backToHome`)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Success;

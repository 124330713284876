import { API_BASE_URL, CONTACT_URL } from 'config/api';
import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getPathInfo } from 'utils/versionUtils';
import {
  setFormData,
  setFormErrors,
  setFormLoading,
  setSubmitFail,
  setSubmitSuccess
} from '../actions';
import * as constants from '../constants';

const { polVersion, lang } = getPathInfo();
const version = polVersion === 'employees' ? 'internal' : 'external';

function* setFail() {
  yield put(setSubmitFail(true));
  yield put(setFormData({ name: '', email: '', message: '' }));
  yield put(setFormErrors({}));
  yield put(setFormLoading(false));
}

type ContactType = SagaReturnType<typeof sendContact>;

const controller = new AbortController();
const { signal } = controller;

function sendContact(
  init: any,
  url: string
): Promise<{
  errors: Array<{ [key: string]: string }>;
  status: number;
  ok: boolean;
}> {
  return fetch(url, init)
    .then((res) => {
      // Abort request after 10s pending
      setTimeout(() => controller.abort(), 10000);
      if (!res.ok && res.status === 400) {
        return res.json();
      }

      if (!res.ok && res.status !== 400) {
        throw Error;
      }

      return res;
    })

    .catch((error) => {
      console.error(error);
      throw Error;
    });
}

function* submitFormSaga(action: {
  type: 'string';
  payload: {
    data: { name: string; email: string; message: string };
    market: string;
  };
}) {
  yield put(setFormLoading(true));
  const { market } = action.payload;
  const init = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': lang
    },

    body: JSON.stringify(action.payload.data),
    signal
  };

  const url = `${API_BASE_URL}/${CONTACT_URL}/${market}?type=${version}`;

  try {
    const result: ContactType = yield call(sendContact, init, url);

    if (result.ok) {
      yield put(setFormData({ name: '', email: '', message: '' }));
      yield put(setFormErrors({}));
      yield put(setSubmitSuccess(true));
    }

    if (result.errors && result.errors[0].name === 'email') {
      yield put(setFormErrors({ email: true }));
    }

    if (
      (result.errors && result.errors[0].name !== 'email') ||
      result.status === 400
    ) {
      yield call(setFail);
    }
  } catch (error) {
    yield call(setFail);
  }
}
function* watchContact() {
  yield takeLatest(constants.SUBMIT_FORM, submitFormSaga);
}

export { watchContact };

import * as constants from '../constants';

// data

export const getLanguages = (
  polVersion: string,
  market: string,
  isCorporate: boolean,
  projectId?: string,
  isSpeakUp?: boolean
) => ({
  type: constants.FETCH_LANGUAGES,
  payload: { polVersion, market, isCorporate, projectId, isSpeakUp }
});

export const setLanguages = (languages: Array<string>) => ({
  type: constants.SET_LANGUAGES,
  payload: { languages }
});

export const getProjectSuccess = (data: any) => ({
  type: constants.FETCH_PROJECT_SUCCESS,
  payload: { data }
});

export const fetchEmployers = () => ({
  type: constants.FETCH_EMPLOYERS
});

export const setEmployers = (employers: {
  [countryCode: string]: Array<{
    projectId: string;
    formalEntityName: string;
  }>;
}) => ({
  type: constants.SET_EMPLOYERS,
  payload: { employers }
});

// ui actions

export const toggleSection = (section: number, expanded: boolean) => ({
  type: constants.TOGGLE_ITEM,
  payload: { section, expanded }
});

export const toggleTabs = (show: boolean) => ({
  type: constants.TOGGLE_TABS,
  payload: { show }
});

export const selectTab = (tab: string) => ({
  type: constants.SET_ACTIVE_TAB,
  payload: { tab }
});

// contact form

export const setFormData = (data: {
  name: string;
  email: string;
  message: string;
}) => ({
  type: constants.SET_FORM_DATA,
  payload: {
    data
  }
});

export const submitForm = (
  data: {
    name: string;
    email: string;
    message: string;
  },
  market: string
) => ({
  type: constants.SUBMIT_FORM,
  payload: { data, market }
});

export const setFormLoading = (loading: boolean) => ({
  type: constants.SET_FORM_LOADING,
  payload: { loading }
});

export const setSubmitSuccess = (success: boolean) => ({
  type: constants.SET_SUBMIT_SUCCESS,
  payload: { success }
});

export const setSubmitFail = (fail: boolean) => ({
  type: constants.SET_SUBMIT_FAIL,
  payload: { fail }
});

export const setFormErrors = (errors: { [key: string]: boolean }) => ({
  type: constants.SET_FORM_ERRORS,
  payload: { errors }
});

import * as constants from '../constants';

export interface UIState {
  expandedItems: { [key: string]: boolean };
  expandedSubItems: { [key: string]: boolean };
  isTabsVisible: boolean;
  activeTab: string;
  submitSuccess: boolean;
  submitFail: boolean;
  formLoading: boolean;
}

const initialState: UIState = {
  expandedItems: {},
  expandedSubItems: {},
  isTabsVisible: false,
  activeTab: 'websiteVisitors',
  submitSuccess: false,
  submitFail: false,
  formLoading: false
};

const uiReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case constants.TOGGLE_ITEM:
      return {
        ...state,
        expandedItems: {
          ...state.expandedItems,
          [action.payload.section]: action.payload.expanded
        }
      };
    case constants.TOGGLE_TABS:
      return { ...state, isTabsVisible: action.payload.show };
    case constants.SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload.tab };
    case constants.SET_SUBMIT_SUCCESS:
      return {
        ...state,
        submitSuccess: action.payload.success,
        formLoading: false
      };
    case constants.SET_SUBMIT_FAIL:
      return { ...state, submitFail: action.payload.fail, formLoading: false };
    case constants.SET_FORM_LOADING:
      return {
        ...state,
        formLoading: action.payload.loading
      };
    default:
      return state;
  }
};

export { uiReducer };

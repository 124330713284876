import cn from 'classnames';
import React from 'react';
import styles from './auxTitle.module.scss';

export interface TitleProps {
  bold?: boolean;
  children: any;
}

const AuxTitle: React.FC<TitleProps> = ({ children }) => (
  <h2 className={cn(styles.heading2)}>{children}</h2>
);

export default AuxTitle;

import cn from 'classnames';
import useTabs from 'hooks/useTabs';
import React from 'react';
import styles from './subNav.module.scss';

export interface SubNavProps {
  items: Array<{ [key: string]: string }>;
  isEmployees: boolean;
}

const SubNav: React.FC<SubNavProps> = ({ items, isEmployees }) => {
  const { setActiveTab, isTabsVisible, activeTab } = useTabs();

  return (
    <ul
      className={cn(styles.root, {
        [styles.active]: isTabsVisible
      })}
    >
      {items &&
        Array.isArray(items) &&
        items.map((item, index) => (
          <li
            key={`subnavItem${index}`}
            onClick={item.tabId ? () => setActiveTab(item.tabId) : null}
          >
            <a
              href={item.anchor}
              className={cn({
                [styles.linkActive]: activeTab === item.tabId,
                uppercase: isEmployees
              })}
            >
              {item.label}
            </a>
          </li>
        ))}
    </ul>
  );
};

export default SubNav;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFormData,
  setFormErrors,
  setSubmitFail,
  setSubmitSuccess,
  submitForm
} from 'store/actions';
import { RootState } from 'store/reducers';

const useForm = (market?: string) => {
  const dispatch = useDispatch();

  const formData = useSelector((state: RootState) => state.data.formData);
  const errors = useSelector((state: RootState) => state.data.formErrors);
  const { name, email, message } = formData;

  const [isValid, setValid] = useState(false);

  const isDirty = () => !!(formData.message && formData.message.length > 0);

  useEffect(() => {
    const allowSubmit = allFilled() && isEmailValid(email);
    setValid(allowSubmit);
  });

  const allFilled = () =>
    name &&
    name.trim().length > 0 &&
    email &&
    email.trim().length > 0 &&
    message &&
    message.trim().length > 0;

  const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
  );

  const isEmailValid = (emailValue: string) => validEmailRegex.test(emailValue);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    const input = event.target.name;

    dispatch(setFormData({ ...formData, [event.target.name]: value }));

    if (input === 'email') {
      if (isEmailValid(value) || value.trim().length < 7) {
        dispatch(setFormErrors({ ...errors, email: false }));
      } else {
        dispatch(setFormErrors({ ...errors, email: true }));
      }
    }
  };

  const handleSubmitForm = (e: any) => {
    e.preventDefault();

    if (isValid) {
      dispatch(submitForm(formData, market));
    }
  };

  const clearSubmit = () => {
    dispatch(setSubmitSuccess(false));
    dispatch(setSubmitFail(false));
  };

  return {
    formData,
    errors,
    isValid,
    isDirty,
    handleInputChange,
    handleSubmitForm,
    clearSubmit
  };
};

export default useForm;

import { isAuthenticated } from 'auth/authHandler';
import React from 'react';
import Loading from 'screens/Loading';

export interface SecureProps {
  children: React.ReactNode;
}

const Secured: React.FC<SecureProps> = ({ children }) => (
  <>{isAuthenticated() ? children : <Loading />}</>
);

export default Secured;

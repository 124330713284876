const host = window.location.hostname;
const baseURL = window.location.origin;

// sanitaze URL host by checking that the host of a URL is in a set of allowed hosts.
const allowedHosts = [
  'carlsberggroup.com',
  'carlsberggroup',
  'compliance.carlsberggroup.com'
];

const isProd = allowedHosts.includes(host);

const getEnvAlias = (url: string) => {
  if (url.includes('localhost')) {
    return 'dev';
  }
  if (url.includes('dev')) {
    return 'dev';
  }
  if (url.includes('stg')) {
    return 'stg';
  }
  return 'prd';
};

const getEnvAliasCx = (url: string) => {
  if (url.includes('localhost')) {
    return 'dev';
  }
  if (url.includes('dev')) {
    return 'dev';
  }
  if (url.includes('stg')) {
    return 'stg';
  }
  return 'live';
};

const DATACAT_ENV = {
  dev: 'https://dev-digitalstudio-globalpolicies.euwest01.umbraco.io',
  stg: 'https://stage-digitalstudio-globalpolicies.euwest01.umbraco.io',
  prd: 'https://digitalstudio-globalpolicies.euwest01.umbraco.io'
};

const env = getEnvAlias(host);
const envCx = getEnvAliasCx(host);

export {
  DATACAT_ENV,
  baseURL,
  env,
  envCx,
  getEnvAlias,
  getEnvAliasCx,
  host,
  isProd
};

import { useDispatch, useSelector } from 'react-redux';
import { toggleSection } from 'store/actions';
import { RootState } from 'store/reducers';

const useToggleText = () => {
  const expandedItems = useSelector(
    (state: RootState) => state.ui.expandedItems
  );
  const dispatch = useDispatch();

  const toggleText = (section: any, expanded: boolean) => {
    dispatch(toggleSection(section, expanded));
  };

  const isExpanded = (section: any) => expandedItems[section];

  return { toggleText, isExpanded };
};

export default useToggleText;

import { useDispatch, useSelector } from 'react-redux';
import { selectTab, toggleTabs } from 'store/actions';
import { RootState } from 'store/reducers';
import { scrollToTop } from 'utils/scrollPage';

const useTabs = () => {
  const dispatch = useDispatch();

  const isTabsVisible = useSelector(
    (state: RootState) => state.ui.isTabsVisible
  );

  const activeTab = useSelector((state: RootState) => state.ui.activeTab);

  const showTabs = (show: boolean) => {
    dispatch(toggleTabs(show));
  };

  const setActiveTab = (tab: string) => {
    dispatch(selectTab(tab));
    showTabs(true);
    scrollToTop();
  };

  return {
    showTabs,
    isTabsVisible,
    setActiveTab,
    activeTab
  };
};

export default useTabs;

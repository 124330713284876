import loading from 'assets/img/loading.svg';
import React from 'react';
import styles from './loading.module.scss';

const Loading = () => (
  <div className={styles.root}>
    <img src={loading} alt="carlsberg group logo" />
  </div>
);

export default Loading;

import { AnyAction, applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootReducer, { RootState } from './reducers';
import rootSaga from './sagas';

export type AppStore = Store<RootState, AnyAction>;

const buildStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const store = createStore<RootState, AnyAction, unknown, unknown>(
    rootReducer(),
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);
  return store;
};

export default buildStore;

import downloadIcon from 'assets/img/download-icon.svg';
import cn from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import { i18nPath } from 'config/i18nNs';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import {
  parseMarketsListToOptions,
  translateLangName
} from 'utils/countriesAndLangs';
import { getPathInfo } from 'utils/versionUtils';
import styles from './speakUp.module.scss';

const SpeakUp = () => {
  const { lang } = getPathInfo();
  const isRTL = lang === 'ug';

  const [selectedMarket, setSelectedMarket] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languagesList, setLanguagesList] = useState([]);
  const [entitesList, setEntitiesList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const { t } = useTranslation(['dictionary', 'speakUp']);

  const marketsList =
    t(`${i18nPath.speakUp}`, { countries: [] }).countries || [];

  const onMarketUpdate = (item: {
    label: string;
    value: {
      code: string;
      flag: string;
      languagesAvailable: { languageCode: string; file: string }[];
    };
  }) => {
    setEntitiesList([]);
    const { languagesAvailable } = item?.value;
    const parsedLanguagesList = languagesAvailable.map(
      (language: { file: string; languageCode: string }) => {
        const langName = translateLangName(language?.languageCode, lang);
        return { label: langName, value: language?.languageCode };
      }
    );

    setSelectedMarket(item);
    setLanguagesList(parsedLanguagesList);
    setSelectedLanguage(null);
  };

  const onLangUpdate = (item: { value: string; label: string }) => {
    setEntitiesList([]);
    const { value: code } = item;
    const { code: currentMarket } = selectedMarket?.value;
    const marketInfo = marketsList.find(
      (marketItem: { code: string }) => marketItem.code === currentMarket
    );
    const currentMarketLangs = marketInfo?.languagesAvailable;
    const languageInfo = marketInfo?.languagesAvailable.find(
      (langItem: { languageCode: string; flag: string }) =>
        langItem.languageCode === code
    );

    const {
      file: pdfFileUrl,
      languageCode,
      contentType,
      entities
    } = languageInfo;
    const langName = translateLangName(languageCode, lang);

    // Poland exception, there is more than one entity per language,
    // display entities dropdown
    if (contentType === 'speakUpElementLanguageMultipleEntities') {
      const parsedEntitiesList = entities?.map(
        (entity: { file: string; label: string }) => ({
          label: entity?.label,
          value: entity?.label
        })
      );
      setEntitiesList(parsedEntitiesList);
      setSelectedLanguage({ label: langName, value: languageCode });
      return;
    }

    setEntitiesList([]);
    setPdfUrl(pdfFileUrl);
    setSelectedLanguage({ label: langName, value: languageCode });
  };

  const onEntityUpdate = (item: { value: string; label: string }) => {
    const { value: entity } = item;
    const { code: currentMarket } = selectedMarket?.value;
    const marketInfo = marketsList.find(
      (marketItem: { code: string }) => marketItem.code === currentMarket
    );

    const languageInfo = marketInfo?.languagesAvailable.find(
      (langItem: { languageCode: string }) =>
        langItem.languageCode === selectedLanguage?.value
    );
    const entityInfo = languageInfo?.entities.find(
      (entityItem: { label: string; file: string }) =>
        entityItem.label === entity
    );

    const { file: pdfFileUrl } = entityInfo;
    setPdfUrl(pdfFileUrl);
    setSelectedEntity(item);
  };

  const onDownloadPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <section className={cn(styles.root, { [styles.rtl]: isRTL })}>
      <h3 className={styles.title}>
        {t(`${i18nPath.dictionary}.downloadManual`)}
      </h3>

      <Row>
        <Col sm={12} md={6} className={styles.column}>
          <div className={styles.topMargin}>
            <label className={styles.label} htmlFor="select-country">
              {t(`${i18nPath.dictionary}.selectYourCountry`)}
            </label>
            <Dropdown
              options={parseMarketsListToOptions(marketsList, lang)}
              value={selectedMarket}
              onChange={onMarketUpdate}
              placeholder={t(`${i18nPath.dictionary}.selectYourCountry`)}
              displayFlags
            />
          </div>
        </Col>
      </Row>
      <Row className={styles.downloadRow}>
        <Col sm={12} md={6}>
          <Row>
            <Col sm={12}>
              <label className={styles.label} htmlFor="select-language">
                {t(`${i18nPath.dictionary}.selectLanguagePlaceholder`)}
              </label>

              <Dropdown
                options={languagesList}
                value={selectedLanguage}
                onChange={onLangUpdate}
                placeholder={t(
                  `${i18nPath.dictionary}.selectLanguagePlaceholder`
                )}
                disabled={!selectedMarket}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {entitesList?.length > 0 && (
        <Row className={styles.downloadRow}>
          <Col sm={12} md={6}>
            <Row>
              <Col sm={12}>
                <label className={styles.label} htmlFor="select-language">
                  {t(`${i18nPath.dictionary}.selectEntityPlaceholder`)}
                </label>
                <Dropdown
                  options={entitesList}
                  value={selectedEntity}
                  onChange={onEntityUpdate}
                  placeholder={t(
                    `${i18nPath.dictionary}.selectEntityPlaceholder`
                  )}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row className={styles.buttonRow}>
        <Col sm={12} md={6}>
          <Button
            variant="primary"
            fullWidth
            onClick={onDownloadPdf}
            disabled={!pdfUrl}
            icon={downloadIcon}
          >
            <div className={styles.btnContent}>
              <span className={styles.btnIcon}>
                <img alt="download icon" src={downloadIcon} />
              </span>
              {t(`${i18nPath.dictionary}.downloadButton`, 'Download Manual')}
            </div>
          </Button>
        </Col>
      </Row>
    </section>
  );
};

export default SpeakUp;

const defaultTheme = {
  '--font-primary': "'Montserrat', sans-serif",
  '--font-secondary': "'Montserrat', sans-serif",
  '--text-primary': '#1C2025',
  '--buttons-primary': '#1C2025',
  '--text-secondary': '#1C2025',
  '--icons-color': '#000000',
  '--sidenav-color': '#8ba0b9',
  '--footer-background': '#f1f4f8',
  '--footer-color': '#8ba0b9',
  '--footer-logo-color': '#1C2025',
  '--nav-color': '#8ba0b9',
  '--nav-font-size': '14px'
};

const greekTheme = {
  '--font-primary': "'Ubuntu', sans-serif",
  '--font-secondary': "'Ubuntu', sans-serif",
  '--text-primary': '#1C2025',
  '--buttons-primary': '#1C2025',
  '--text-secondary': '#1C2025',
  '--icons-color': '#000000',
  '--sidenav-color': '#8ba0b9',
  '--footer-background': '#f1f4f8',
  '--footer-color': '#8ba0b9',
  '--footer-logo-color': '#1C2025',
  '--nav-color': '#8ba0b9',
  '--nav-font-size': '14px'
};

const uyghurTheme = {
  '--font-primary': "'Alkatip', sans-serif",
  '--font-secondary': "'Alkatip', sans-serif",
  '--text-primary': '#1C2025',
  '--buttons-primary': '#1C2025',
  '--text-secondary': '#1C2025',
  '--icons-color': '#000000',
  '--sidenav-color': '#8ba0b9',
  '--footer-background': '#f1f4f8',
  '--footer-color': '#8ba0b9',
  '--footer-logo-color': '#1C2025',
  '--nav-color': '#8ba0b9',
  '--nav-font-size': '14px'
};

const employeesTheme = {
  '--font-primary': "'CarlsbergSans', sans-serif",
  '--font-secondary': "'Montserrat', sans-serif",
  '--text-primary': '#00321E',
  '--buttons-primary': '#00321E',
  '--text-secondary': '#17B169',
  '--icons-color': '#000000',
  '--sidenav-color': '#00321E',
  '--footer-background': '#ffffff',
  '--footer-color': '#17B14B',
  '--footer-logo-color': '#17B169',
  '--nav-color': '#00321E',
  '--nav-font-size': '13px'
};

const getTheme = (lang: string, polVersion: string) => {
  if (lang === 'ug') {
    return uyghurTheme;
  }

  if (lang === 'el') {
    return greekTheme;
  }

  if (lang !== 'el' && polVersion === 'employees') {
    return employeesTheme;
  }

  return defaultTheme;
};

export default getTheme;

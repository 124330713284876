const config = {
  global: {
    cookieCardLabels: [
      'name',
      'source',
      'purpose',
      'duration',
      'additionalInfo'
    ],
    homeLinks: {
      purpose: './privacy#data-usage',
      collectedData: './privacy'
    }
  },
  employees: {
    footerLinks: [
      {
        i18nKey: 'contacts',
        path: './contact'
      }
    ]
  },
  externals: {
    footerLinks: [
      {
        i18nKey: 'contacts',
        path: './contact'
      }
    ]
  }
};

export default config;

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const initSmoothScroll = () => {
  document.querySelectorAll('a[href^="#"]').forEach((trigger: any) => {
    trigger.onclick = function (e: any) {
      e.preventDefault();
      const hash = this.getAttribute('href');
      const target = document.querySelector(hash);

      if (!target) {
        return;
      }

      const headerOffset = 60;
      const elementPosition = target.offsetTop;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    };
  });
};

const scrollToAnchor = (hash: string) => {
  let anchor = null;

  if (!hash) {
    return;
  }

  const hashString = hash.toString();
  const hashId = hashString.replace('#', '');
  const links = document.querySelectorAll('a');
  links.forEach((link) => {
    if (link.id?.toLowerCase() === hashId.toLowerCase()) {
      anchor = link;
    }
  });

  if (!anchor) {
    return;
  }

  const headerOffset = 30;
  const elementPosition = anchor.offsetTop;

  const offsetPosition = elementPosition - headerOffset;

  const scrollToElement = () => {
    window.scrollTo({
      top: offsetPosition
    });
  };

  window.setTimeout(scrollToElement, 5);
};

export { initSmoothScroll, scrollToAnchor, scrollToTop };

import cn from 'classnames';
import React from 'react';
import styles from './icon.module.scss';

export interface IconProps {
  name: string;
  size?: number;
}

const Icon: React.FC<IconProps> = ({ name, size }) => (
  <span
    className={cn(name, styles.root)}
    style={{ fontSize: size ? `${size}px` : '20px' }}
  />
);

export default Icon;

import { env } from 'config/env';
import { HQemployeesId, idsConfig } from 'config/ids';
import { availableLangs, defaultLang } from '../config/locale';

// TO DO, write a unit test for this utils
// it's easy breakable

const { pathname } = window.location;

const isLangAvailable = (lang: string) => availableLangs.includes(lang);

const isEmployeesIntro = (path: string) =>
  path.includes('/employees/home') || path.includes('/employees/employer');

const isVersionValid = (version: string) =>
  version === 'employees' || version === 'externals';

// check if URL is valid, otherwise, redirect to valid path
const resolveURL = (
  lang: string,
  locale: string,
  market: string,
  version: string
) => {
  if (pathname === '/employees' || pathname === '/employees/') {
    window.location.pathname = `/employees/home`;
  }

  // this path will take to 404 page, no need to load info
  // from URL
  if (typeof version === 'undefined') {
    return;
  }

  // if policy version is not present in URL,
  // redirect to /externals policies
  if (!isVersionValid(version) && version.match('^[a-z]{2}-[a-z]{2}$')) {
    window.location.pathname = `externals/${version}/`;
  }

  if (typeof market === 'undefined' || typeof lang === 'undefined') {
    return;
  }

  if (!isLangAvailable(lang) && !isEmployeesIntro(pathname)) {
    window.location.pathname = `${version}/${defaultLang}-${market}`;
  }

  if (noURLSlash(locale, lang) && !isEmployeesIntro(pathname)) {
    window.location.pathname = `${pathname}/`;
  }
};

const getPathInfo = () => {
  let lang;
  let market;
  let polVersion;
  let locPath;

  if (pathname === '/') {
    return { lang, market };
  }

  const splitPath = pathname.split('/');
  polVersion = splitPath?.[1];

  if (!isVersionValid(polVersion) && !polVersion.match('^[a-z]{2}-[a-z]{2}$')) {
    polVersion = '404';
  }
  // lang-market
  const locale = splitPath?.[2];

  const locArray = locale?.split('-');

  lang = locArray?.[0]?.toLowerCase();
  market = locArray?.[1]?.toLowerCase();

  if (isLangAvailable(lang)) {
    locPath = `${polVersion}/${lang}-${market}`;
  }

  return { lang, market, locPath, locale, polVersion };
};

// check if {lang}-{market} has a slash next to it
const noURLSlash = (locale: string, lang: string) =>
  locale.match('^[a-z]{2}-[a-z]{2}$') &&
  availableLangs.includes(lang) &&
  !pathname.includes(`${locale}/`);

const replaceLang = (url: string, newLang: string) => {
  const urlObj = new URL(url);
  const pathParts = urlObj.pathname.split('/');

  if (pathParts.length >= 3) {
    pathParts[2] = pathParts[2].replace(/^([a-z]{2})-/i, `${newLang}-`);
  }

  urlObj.pathname = pathParts.join('/');
  return urlObj.href;
};

const getProjId = (version: 'global' | 'excom') => {
  const id = idsConfig[env][version];
  return id;
};

const isExcom = (projectId: string) => projectId === idsConfig[env].excom;
const isGlobEmployees = (projectId: string) =>
  projectId === idsConfig[env].global;
const isHQEmployees = (projectId: string, market: string) =>
  projectId === HQemployeesId[env] && market === 'dk';

const getPoliciesLink = (
  mkt: string,
  lng: string,
  version: 'global' | 'excom'
) => `/employees/${lng}-${mkt}/?id=${getProjId(version)}`;

const getUmbracoPolVersion = (polVersion: string) => {
  const umbracoPolVersion = polVersion === 'externals' ? 'ext' : 'emp';
  return umbracoPolVersion;
};

const isSoMeTab = (path: string) => {
  if (path?.includes('social-media')) {
    return true;
  }
  return false;
};

const getPoliciesType = (
  polVersion: string,
  isCorporate: boolean,
  projectId?: string
) => {
  let type;

  if (polVersion === 'employees') {
    const isGlobalEmployer = isGlobEmployees(projectId);
    type = isGlobalEmployer ? 'brands' : 'corporate';
    return type;
  }

  type = isCorporate ? 'corporate' : 'brands';
  return type;
};

export {
  getPathInfo,
  getPoliciesLink,
  getPoliciesType,
  getProjId,
  getUmbracoPolVersion,
  isEmployeesIntro,
  isExcom,
  isGlobEmployees,
  isHQEmployees,
  isSoMeTab,
  isVersionValid,
  replaceLang,
  resolveURL
};

import { AUTH_URL } from 'config/api';
import { baseURL, getEnvAlias } from 'config/env';
import Keycloak, { KeycloakOnLoad } from 'keycloak-js';

const isDevelopment = getEnvAlias(baseURL) === 'dev';

let keycloak: Keycloak.KeycloakInstance;

const getKeycloakConfig = async (isDev: boolean) => {
  const initConfig: {
    onLoad: KeycloakOnLoad;
    promiseType: string;
    checkLoginIframe: boolean;
    enableLogging: boolean;
    silentCheckSsoRedirectUri?: string;
  } = {
    onLoad: 'check-sso',
    promiseType: 'native',
    // we are running sso on a different domain which break under chrome incognito
    checkLoginIframe: !isDev,
    enableLogging: isDev
  };

  return initConfig;
};

const initKeycloak = async () => {
  keycloak = Keycloak({
    realm: 'cx',
    url: `${AUTH_URL}/auth`,
    clientId: 'cx-apps'
  });

  const initConfig = await getKeycloakConfig(isDevelopment);

  return keycloak.init(initConfig);
};

const getKeycloak = () => keycloak;

const login = (locale: string) => {
  const url: string = keycloak.createLoginUrl({
    locale
  });

  window.location.assign(url);
};

const logout = (options?: { redirectUri: string }) => {
  if (!keycloak) return null;

  localStorage.removeItem('showOutletsModal');
  return keycloak.logout(options);
};

const isAuthenticated = async () => {
  if (!keycloak) {
    return initKeycloak().catch((e) => {
      console.warn('Authentication failed', e);
      logout();

      return false;
    });
  }

  return keycloak?.authenticated ?? false;
};

export { getKeycloak, isAuthenticated, login, logout, initKeycloak };

import React from 'react';
import styles from './select.module.scss';

export interface SelectProps {
  handleChange: (tab: string) => void;
  options: Array<{ label: string; value: string }>;
  value: string;
}

const Select: React.FC<SelectProps> = ({
  options = [],
  handleChange,
  value
}) => {
  const changeTab = (evt: any) => {
    handleChange(evt.target.value);
  };

  return (
    <div className={styles.root}>
      <h2> {value} </h2>
      <select onChange={changeTab}>
        {options &&
          options.map((option) => (
            <option value={option.value} key={`option-${option.value}`}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default Select;

import cn from 'classnames';
import FormattedText from 'components/FormattedText';
import { i18nPath } from 'config/i18nNs';
import config from 'config/template';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Body1, { BODY1COLOR } from 'typography/Body1';
import styles from './cookieCard.module.scss';

export interface CookieCardProps {
  info: { [key: string]: string };
}

const labels = config.global.cookieCardLabels;

const CookieCard: React.FC<CookieCardProps> = ({ info }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.labelsColumn} />
      <div className={styles.contentColumn} />
      {labels &&
        labels.map((label, index) => (
          <div className={styles.row} key={`card-row${index}`}>
            <div className={cn(styles.cell, styles.label)}>
              <Body1 color={BODY1COLOR.GREY}>
                {t(`${i18nPath.cookies}.labels.${label}`)}
              </Body1>
            </div>

            <div className={cn(styles.cell, styles.infoCell)}>
              <Body1 key={`card-info${index}`}>
                <FormattedText i18nKey={info[label]} />
              </Body1>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CookieCard;

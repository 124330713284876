import React from 'react';
import { LegalEntityType } from 'types/project';
import { getDefaultEntity, parseEntitiesContent } from 'utils/entities';

const EntitiesList: React.FC<{
  entitiesList: LegalEntityType[];
  children?: React.ReactNode;
}> = ({ entitiesList, children }) => {
  const text = children.toString();
  const defaultEntity = getDefaultEntity(entitiesList);

  return (
    <>
      {entitiesList.map((item) => {
        const parsedContent = parseEntitiesContent(item, text, defaultEntity);
        return (
          <>
            <p key={item.eid}> {parsedContent} </p>
            <span className="break" />
          </>
        );
      })}
    </>
  );
};

export default EntitiesList;

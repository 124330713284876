import { API_BASE_URL } from 'config/api';

const getProjectInfo = async (id: string, market: string) => {
  const controller = new AbortController();
  const { signal } = controller;

  setTimeout(() => controller.abort(), 8000);

  const url = `${API_BASE_URL}/projects/${id}/legal-data/${market}`;

  try {
    const res = await fetch(url, { signal });

    if (!res.ok) {
      console.error('error fetching project data');

      return null;
    }

    const response = res.json();
    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default getProjectInfo;

import { i18nPath } from 'config/i18nNs';
import useTabs from 'hooks/useTabs';
import PolicyTab from 'layout/PolicyTab';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectOptions } from 'utils/format';

export interface PrivacyProps {
  id: string;
}

const PrivacyDetails: React.FC<PrivacyProps> = ({ id }) => {
  const { t } = useTranslation();

  const { activeTab } = useTabs();

  const activities: Array<{
    title: string;
    label: string;
    tabId: string;
    sections: Array<{
      title: string;
      content: string;
      anchor: string;
    }>;
  }> = t(`${i18nPath.globalVariant}.activities`);

  const activitiesLocal: Array<{
    title: string;
    label: string;
    tabId: string;
    sections: Array<{
      title: string;
      content: string;
      anchor: string;
    }>;
  }> = t(`${i18nPath.local}.activities`);
  const tabData =
    activities && activities.find((activity) => activity.tabId === activeTab);
  const activityIndex =
    activities &&
    Array.isArray(activities) &&
    activities.findIndex((element) => element.tabId === activeTab);

  const localIndex =
    activitiesLocal &&
    Array.isArray(activitiesLocal) &&
    activitiesLocal.findIndex((element) => element.tabId === activeTab);

  const tabLocalData =
    activitiesLocal &&
    Array.isArray(activitiesLocal) &&
    activitiesLocal.find((activity) => activity.tabId === activeTab);

  const selectOptions = getSelectOptions(activities);

  return (
    <PolicyTab
      id={id}
      i18nPrefix={`${i18nPath.globalVariant}.activities.${activityIndex}`}
      locali18nPrefix={`${i18nPath.local}.activities.${localIndex}`}
      content={tabData}
      selectOptions={selectOptions}
      localContent={tabLocalData}
    />
  );
};

export default PrivacyDetails;

import { HEADLESS_API_BASE_URL, UMBRACO_API_BASE_URL } from 'config/api';
import { projectId } from 'utils/urlQueries';
import { getPathInfo, isExcom, isGlobEmployees } from 'utils/versionUtils';

const { market, polVersion } = getPathInfo();

type NamespaceType =
  | 'dictionary'
  | 'local'
  | 'cookies-list'
  | 'homepage'
  | 'master'
  | 'speakUp';

const getNamespaces = (
  noPrjInfo?: boolean,
  isHomepage?: boolean
): NamespaceType[] => {
  if (isHomepage) {
    return ['dictionary', 'homepage', 'master'];
  }

  if (noPrjInfo) {
    return ['dictionary', 'master'];
  }

  if (polVersion === 'employees') {
    return ['dictionary', 'local', 'master'];
  }
  // is Externals
  return ['dictionary', 'local', 'cookies-list', 'master', 'speakUp'];
};

const getContentPath = (namespaces: NamespaceType[], isCorporate: boolean) => {
  const contentPath =
    polVersion === 'employees'
      ? resolveEmployeesPath(namespaces[0])
      : resolveExternalsPath(namespaces[0], isCorporate);

  return contentPath;
};

const resolveExternalsPath = (
  namespace: NamespaceType,
  isCorporate: boolean
) => {
  if (namespace === 'dictionary') {
    return `${HEADLESS_API_BASE_URL}/handler?handle={{ns}}$cc={{lng}}`;
  }

  if (namespace === 'speakUp') {
    return `${UMBRACO_API_BASE_URL}/?handle=speak-up$pg=ext`;
  }

  https: if (namespace === 'local' && isCorporate) {
    return `${HEADLESS_API_BASE_URL}/handler?handle=local_corporate$pg=ext$mkt=${market}$cc={{lng}}`;
  }

  if (namespace === 'local' && !isCorporate) {
    return `${HEADLESS_API_BASE_URL}/handler?handle=local_brands$pg=ext$mkt=${market}$cc={{lng}}`;
  }

  // cookies-list
  // master
  return `${HEADLESS_API_BASE_URL}/handler?handle={{ns}}$cc={{lng}}$pg=ext`;
};

const resolveEmployeesPath = (namespace: NamespaceType) => {
  if (namespace === 'dictionary') {
    return `${HEADLESS_API_BASE_URL}/handler?handle={{ns}}$cc={{lng}}`;
  }
  if (namespace === 'homepage') {
    return `${UMBRACO_API_BASE_URL}/?handle=homepage$cc=en$pg=emp`;
  }
  if (namespace === 'local' && isExcom(projectId)) {
    return `${HEADLESS_API_BASE_URL}/handler?handle=local_excom$pg=emp$mkt=${market}$cc={{lng}}`;
  }

  // we need this exception in order to handle the case of poland, where 2 different entities have
  // different legal framings, so we need to get a different content for each
  // this is a temporary fix
  if (
    namespace === 'local' &&
    !isGlobEmployees(projectId) &&
    // prd project id
    (projectId === 'b06a7723-5a42-4b94-8cca-62ddd6d0ff63' ||
      // stg project id
      projectId === '2ad85821-85fa-4367-84f0-6b0879732360')
  ) {
    return `${HEADLESS_API_BASE_URL}/handler?handle=local_corporate_alt$pg=emp$mkt=${market}$cc={{lng}}`;
  }

  // if its not GlobEmployees = it is Corporate, serve corporate content
  if (namespace === 'local' && !isGlobEmployees(projectId)) {
    return `${HEADLESS_API_BASE_URL}/handler?handle=local_corporate$pg=emp$mkt=${market}$cc={{lng}}`;
  }

  if (namespace === 'local' && isGlobEmployees(projectId)) {
    return `${HEADLESS_API_BASE_URL}/handler?handle=local_brands$pg=emp$mkt=${market}$cc={{lng}}`;
  }

  // master content
  return `${UMBRACO_API_BASE_URL}/?handle={{ns}}$cc={{lng}}$pg=emp`;
};

export {
  NamespaceType,
  getContentPath,
  getNamespaces,
  resolveEmployeesPath,
  resolveExternalsPath
};

import cn from 'classnames';
import React from 'react';
import styles from './caption.module.scss';

export interface CaptionProps {
  bold?: boolean;
  children: any;
}

const Caption: React.FC<CaptionProps> = ({
  children,
  bold = false
}: CaptionProps) => (
  <p className={cn(styles.caption, { [styles.bold]: bold })}>{children}</p>
);

export default Caption;

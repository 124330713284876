import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/da.json'));
countries.registerLocale(require('i18n-iso-countries/langs/az.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ru.json'));
countries.registerLocale(require('i18n-iso-countries/langs/bg.json'));
countries.registerLocale(require('i18n-iso-countries/langs/km.json'));
countries.registerLocale(require('i18n-iso-countries/langs/zh.json'));
countries.registerLocale(require('i18n-iso-countries/langs/hr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/et.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/el.json'));
countries.registerLocale(require('i18n-iso-countries/langs/hu.json'));
countries.registerLocale(require('i18n-iso-countries/langs/hi.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
countries.registerLocale(require('i18n-iso-countries/langs/kk.json'));
countries.registerLocale(require('i18n-iso-countries/langs/lv.json'));
countries.registerLocale(require('i18n-iso-countries/langs/lt.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ms.json'));
countries.registerLocale(require('i18n-iso-countries/langs/nb.json'));
countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
countries.registerLocale(require('i18n-iso-countries/langs/zh.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/uk.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sr.json'));
countries.registerLocale(require('i18n-iso-countries/langs/ug.json'));

export const parseMarketsListToOptions = (
  marketsWithFlagList: { flag: string; code: string }[],
  currentLanguage: string
) =>
  marketsWithFlagList.map((market: { flag: string; code: string }) => {
    let countryName = countries.getName(market.code, currentLanguage);
    if (!countryName) {
      countryName = countries.getName(market.code, 'en');
    }
    return { label: countryName, value: market };
  });

export const translateLangName = (
  langCode: string,
  currentLanguage: string
) => {
  let languageNames = new Intl.DisplayNames([currentLanguage], {
    type: 'language'
  });
  let langName = languageNames.of(langCode);
  if (langName === langCode) {
    languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
    langName = languageNames.of(langCode);
  }
  langName = langName?.charAt(0)?.toUpperCase() + langName?.slice(1);
  return langName;
};

import cn from 'classnames';
import React from 'react';
import styles from './heading2.module.scss';

export enum TITLECOLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GREY = 'grey'
}

export interface TitleProps {
  bold?: boolean;
  color?: TITLECOLOR;
  children: any;
}

const Title: React.FC<TitleProps> = ({
  children,
  color = TITLECOLOR.PRIMARY,
  bold = false
}) => (
  <h2 className={cn(styles.heading2, styles[color], { [styles.bold]: bold })}>
    {children}
  </h2>
);

export default Title;

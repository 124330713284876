import cn from 'classnames';
import React from 'react';
import styles from './body1.module.scss';

export enum BODY1COLOR {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GREY = 'grey'
}

export interface Body1Props {
  bold?: boolean;
  color?: BODY1COLOR;
  children?: any;
  className?: string;
  hasIcon?: boolean;
}

const Body1: React.FC<Body1Props> = ({
  children,
  className,
  color = BODY1COLOR.PRIMARY,
  bold = false,
  hasIcon
}) => (
  <p
    className={cn(
      styles.body1,
      styles[color],
      {
        [styles.bold]: bold
      },
      className,
      {
        [styles.icon]: hasIcon
      }
    )}
  >
    {children}
  </p>
);

export default Body1;

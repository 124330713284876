import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loading from 'screens/Loading';
import { getLanguages, getProjectSuccess, setLanguages } from 'store/actions';
import { RootState } from 'store/reducers';
import { ProjectType } from 'types/project';
import getTheme from 'utils/getTheme';
import { projectId } from 'utils/urlQueries';
import { getPathInfo, isExcom } from 'utils/versionUtils';
import Router from './router';

export interface AppProps {
  projectInfo: ProjectType;
}

const App = ({ projectInfo }: AppProps) => {
  const dispatch = useDispatch();
  const { isCorporate } = useSelector(
    (state: RootState) => state.data.projectData
  );

  const { locPath, lang, market, polVersion } = getPathInfo();

  useEffect(() => {
    if (projectInfo) {
      dispatch(getProjectSuccess(projectInfo));
    }
  }, []);

  const location = window?.location?.pathname;
  const isSpeakUp = location.includes('speak-up');

  useEffect(() => {
    const excom = isExcom(projectId);

    // employees excom content should be displayed in english only
    if (excom) {
      dispatch(setLanguages(['en']));
      return;
    }

    // get available languages according to the policy version market, and the isCorporate flag
    dispatch(
      getLanguages(polVersion, market, isCorporate, projectId, isSpeakUp)
    );
  }, [isCorporate]);

  const customTheme = getTheme(lang, polVersion) as React.CSSProperties;

  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <div style={customTheme}>
          <Router locPath={locPath} />
        </div>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;

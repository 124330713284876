import React from 'react';
import Select, { OptionProps } from 'react-select';
import styles from './dropdown.module.scss';
import { DropdownProps } from './types';

const selectStyles = {
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    paddingRight: '12px',
    svg: { width: '24px', height: '24px' }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  control: (base: any, state: any) => ({
    ...base,
    border: '1px solid #B5BDBD',
    borderRadius: 0,
    height: '48px',
    paddingLeft: '6px',
    borderColor: '#333',
    boxShadow: 0,
    ':hover': {
      borderColor: state.isActive ? '#B5BDBD' : '#B5BDBD',
      boxShadow: 0
    },
    '.arrow': {
      opacity: state.isFocus ? '0.1' : '1'
    }
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #B5BDBD',
    borderRadius: 0,
    top: '40px',
    position: 'absolute',
    color: '#212833',
    padding: 0
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '16px',
    backgroundColor: state.isSelected ? '#fff' : '#fff',
    fontWeight: state.isSelected ? '700' : '400',
    color: '#212833',
    ':hover': {
      backgroundColor: '#EDEDED'
    }
  })
};

const Option = ({ innerProps, data, label }: OptionProps) => {
  const { value = { flag: '' } } = data;
  const { flag } = value;

  return (
    <div className={styles.option} {...innerProps}>
      <span className={styles.flag}>
        <img src={value?.flag} alt={label}></img>
      </span>
      {label}
    </div>
  );
};

const Dropdown: React.FC<DropdownProps> = ({
  value,
  options,
  onChange,
  displayFlags = false,
  placeholder,
  disabled = false
}) => {
  const customComponents = displayFlags ? { Option } : {};
  return (
    <div className={styles.root} data-testid="dropdown">
      <Select
        value={value}
        options={options}
        onChange={onChange}
        styles={selectStyles}
        placeholder={placeholder}
        isDisabled={disabled}
        components={customComponents}
      />
    </div>
  );
};

export default Dropdown;

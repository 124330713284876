import Dropdown from 'components/Dropdown';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import styles from './employer.module.scss';
import selectStyles from './selectStyles';

interface EmployerSelectProps {
  countriesList: Array<{ value: string; label: string }>;
  employersList?: Array<{ value: string; label: string }>;
  selectedCountry: { value: string; label: string };
  selectedEmployer: { value: string; label: string };
  handleChangeCountry: (event: any) => void;
  handleChangeEmpl: (event: any) => void;
  employersDisabled: boolean;
  submitDisabled: boolean;
  handleSubmit: (event: any) => void;
}

const EmployerSelect: React.FC<EmployerSelectProps> = ({
  countriesList,
  selectedCountry,
  handleChangeCountry,
  employersDisabled,
  selectedEmployer,
  handleChangeEmpl,
  employersList,
  submitDisabled,
  handleSubmit
}) => {
  const { t } = useTranslation();
  const sortOptions = (options: Array<{ value: string; label: string }>) =>
    options.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Row className={styles.root}>
      <Col sm={12} lg={4}>
        <label htmlFor="countries">
          <p className={styles.label}>
            1. {t(`${i18nPath.dictionary}.selectCountry`)}
          </p>
        </label>
        <Dropdown
          value={selectedCountry}
          options={sortOptions(countriesList)}
          onChange={handleChangeCountry}
          styles={selectStyles}
        />
      </Col>
      <Col sm={0} lg={1} />

      <Col sm={12} lg={4} className={styles.block}>
        <label htmlFor="employers">
          <p className={styles.label}>
            2.
            {t(`${i18nPath.dictionary}.selectEmployerName`)}
          </p>
        </label>

        <Select
          isDisabled={employersDisabled}
          value={selectedEmployer}
          options={sortOptions(employersList)}
          onChange={handleChangeEmpl}
          styles={selectStyles}
        />
      </Col>

      <Col sm={0} lg={1} />

      <Col sm={12} lg={2} className={styles.buttonWrapper}>
        <button
          className={styles.button}
          type="button"
          disabled={submitDisabled}
          onClick={handleSubmit}
        >
          {t(`${i18nPath.dictionary}.go`)}
        </button>
      </Col>
    </Row>
  );
};

export default EmployerSelect;

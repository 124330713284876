import cn from 'classnames';
import React from 'react';
import styles from './menu.module.scss';

export interface MenuProps {
  children: React.ReactNode;
  active: boolean;
}

const Menu: React.FC<MenuProps> = ({ children, active }) => (
  <div
    className={cn(styles.root, {
      [styles.active]: active
    })}
  >
    {children}
  </div>
);

export default Menu;

import Button from 'components/Button';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Heading1 } from 'typography';
import { getPathInfo } from 'utils/versionUtils';
import styles from './error.module.scss';

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const { market, lang } = getPathInfo();

  const backToHome = () => {
    window.location.href = `/${lang}-${market}?id=${query.get('id')}`;
  };

  return (
    <Row>
      <Col sm={0} md={1} />
      <Col sm={11}>
        <div className={styles.root}>
          <Heading1>
            <Trans
              i18nKey={`${i18nPath.master}.pages.contactPage.view.messages.error.title`}
            />
          </Heading1>
          <p className={styles.desc}>
            <Trans
              i18nKey={`${i18nPath.master}.pages.contactPage.view.messages.error.description`}
              components={{
                break: <span className="break" />
              }}
            />
          </p>
          <div className={styles.button}>
            <Button size="large" onClick={backToHome}>
              <Icon name="icon-diagonal-up-left" size={16} />
              {t(`${i18nPath.dictionary}.backToHome`)}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default NotFound;

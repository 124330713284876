import cn from 'classnames';
import React from 'react';
import styles from './menuBtn.module.scss';

export interface MenuBtnProps {
  toggleActive: () => void;
  active: boolean;
}

const MenuBtn: React.FC<MenuBtnProps> = ({ active, toggleActive }) => (
  <div
    data-testid="menu-btn"
    className={cn(styles.root, { [styles.active]: active })}
    onClick={toggleActive}
  >
    <span />
    <span />
  </div>
);

export default MenuBtn;

import cn from 'classnames';
import Button from 'components/Button';
import FormattedText from 'components/FormattedText';
import Icon from 'components/Icon';
import { i18nPath } from 'config/i18nNs';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Body1, Heading1, Heading2 } from 'typography';
import { getPoliciesLink } from 'utils/versionUtils';
import styles from './home.module.scss';

export interface HomepageProps {
  excom: boolean;
}

const Homepage: React.FC<HomepageProps> = ({ excom }) => {
  const { t } = useTranslation(['homepage', 'dictionary']);

  const goToPolicies = (
    market: string,
    lang: string,
    version: 'global' | 'excom'
  ) => {
    const newLocation = getPoliciesLink(market, lang, version);
    window.location.href = excom ? `${newLocation}&ex=true` : newLocation;
  };

  return (
    <>
      <Row className={styles.intro}>
        <Col sm={12} className="column">
          <div className={styles.title}>
            <Heading1>
              <span className="uppercase">
                {excom
                  ? t(`${i18nPath.homepage}.home.exComTitle`)
                  : t(`${i18nPath.homepage}.home.title`)}
              </span>
            </Heading1>
          </div>

          <FormattedText i18nKey={`${i18nPath.homepage}.home.intro`} />
        </Col>
      </Row>

      <Row className={styles.options}>
        <Col sm={12} lg={6} className={cn('column', styles.block)}>
          <div className={styles.title}>
            <Heading2>
              <Icon name="icon-carlsber-symbol-fill" size={24} />
              {t(`${i18nPath.homepage}.global.title`)}
            </Heading2>
          </div>
          <Body1>{t(`${i18nPath.homepage}.global.intro`)}</Body1>
          <div className={styles.button}>
            <Button
              variant="primary"
              onClick={() => goToPolicies('dk', 'en', 'global')}
            >
              {t(`${i18nPath.dictionary}.readMore`)}
            </Button>
          </div>
        </Col>
        <Col sm={12} lg={6} className={cn('column', styles.block)}>
          <Heading2>
            <Icon name="icon-Global-globe" size={24} />
            {excom
              ? t(`${i18nPath.homepage}.exCom.title`)
              : t(`${i18nPath.homepage}.corporate.title`)}
          </Heading2>

          {excom ? (
            <Body1>{t(`${i18nPath.homepage}.exCom.intro`)}</Body1>
          ) : (
            <Body1>{t(`${i18nPath.homepage}.corporate.intro`)}</Body1>
          )}
          <div className={styles.button}>
            {excom ? (
              <Button
                variant="primary"
                onClick={() => goToPolicies('dk', 'en', 'excom')}
              >
                {t(`${i18nPath.dictionary}.readMore`)}
              </Button>
            ) : (
              <Link to="/employees/employer/">
                <Button variant="primary">
                  {t(`${i18nPath.dictionary}.readMore`)}
                </Button>
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Homepage;

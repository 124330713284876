// data constants

export const FETCH_EMPLOYERS = 'FETCH_EMPLOYERS';
export const SET_EMPLOYERS = 'SET_EMPLOYERS';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_MARKET_LANGUAGES = 'SET_MARKET_LANGUAGES';
export const FETCH_PROJECT_DATA = 'FETCH_PROJECT_DATA';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_FAIL = 'FETCH_PROJECT_FAIL';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_LOCAL_DATA = 'FETCH_LOCAL_DATA';
export const FETCH_LOCAL_DATA_SUCCESS = 'FETCH_LOCAL_DATA_SUCCESS';
export const SET_PROJECT_INFO = 'SET_PROJECT_INFO';
export const SET_VALID_PROJECT = 'SET_VALID_PROJECT';

// ui constants

export const TOGGLE_ITEM = 'TOGGLE_ITEM';
export const TOGGLE_TABS = 'TOGGLE_TABS';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

// contact form

export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SUBMIT_FORM = 'SUBMIT_FORM';
export const SET_SUBMIT_SUCCESS = 'SET_SUBMIT_SUCCESS';
export const SET_SUBMIT_FAIL = 'SET_SUBMIT_FAIL';
export const SET_FORM_ERRORS = 'SET_FORM_ERRORS';
export const SET_FORM_LOADING = 'SET_FORM_LOADING';

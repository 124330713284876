const availableLangs = [
  'en',
  'el',
  'dk',
  'fr',
  'da',
  'it',
  'de',
  'fi',
  'hr',
  'hu',
  'pl',
  'sv',
  'bg',
  'nb',
  'et',
  'lv',
  'az',
  'es',
  'lt',
  'nl',
  'vi',
  'lo',
  'ru',
  'km',
  'zh',
  'hk',
  'hi',
  'it',
  'kk',
  'ms',
  'my',
  'sr',
  'uk',
  'ug'
];

const marketLocalLang: { [market: string]: string } = {
  bg: 'bg',
  hr: 'hr',
  dk: 'da',
  ee: 'et',
  fi: 'fi',
  fr: 'fr',
  de: 'de',
  gr: 'el',
  hu: 'hu',
  it: 'it',
  lv: 'lv',
  lt: 'lt',
  no: 'nb',
  pl: 'pl',
  se: 'sv',
  ch: 'de',
  gb: 'en',
  nl: 'nl',
  cy: 'el'
};

const defaultLang = 'en';

export { availableLangs, defaultLang, marketLocalLang };

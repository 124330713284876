import React from 'react';
import styles from './footerLang.module.scss';

export interface FooterLangProps {
  options: Array<string>;
  handleChangeLang: (event: any) => void;
  selected: string;
}

const FooterLang: React.FC<FooterLangProps> = ({
  options,
  handleChangeLang,
  selected
}) => (
  <select className={styles.root} onChange={handleChangeLang} value={selected}>
    {options &&
      options.map((option, index) => {
        const langCode = option;
        return (
          <option value={langCode} key={`${langCode}${index}`}>
            {langCode}
          </option>
        );
      })}
  </select>
);
export default FooterLang;

import Footer from 'components/Footer';
import Header from 'components/Header';
import Content from 'layout/Content';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from 'utils/scrollPage';
import { getPathInfo } from 'utils/versionUtils';
import styles from './layout.module.scss';

export interface PageLayoutProps {
  children: React.ReactNode;
  noNav?: boolean;
  listLangs?: boolean;
  alias?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  noNav,
  listLangs = true,
  alias = ''
}) => {
  const { pathname } = useLocation();
  const { polVersion } = getPathInfo();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <>
      <div className={styles.root}>
        <Container>
          <Header
            noNav={noNav}
            isEmployees={polVersion === 'employees'}
            listLangs={listLangs}
            alias={alias}
          />
          <Content>{children}</Content>
        </Container>
      </div>
      <Footer
        noNav={noNav}
        isEmployees={polVersion === 'employees'}
        listLangs={listLangs}
      />
    </>
  );
};

export default PageLayout;

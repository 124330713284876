import { combineReducers } from 'redux';
import { dataReducer, DataState } from './data';
import { uiReducer, UIState } from './ui';

export interface RootState {
  data: DataState;
  ui: UIState;
}

const rootReducer = () =>
  combineReducers({
    data: dataReducer,
    ui: uiReducer
  });

export default rootReducer;

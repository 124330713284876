import React from 'react';
import styles from './heading4.module.scss';

export interface Heading4Props {
  children: any;
}

const Heading4: React.FC<Heading4Props> = ({ children }) => (
  <h4 className={styles.heading4}>{children}</h4>
);

export default Heading4;
